import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();
/* ====================================================
    Adding class to mobile main menu
   ==================================================== */ 
$(document).ready(function($){
        // Get current url
        // Select an a element that has the matching href and apply a class of 'active'. Also prepend a - to the content of the link
        var url = window.location.href;

        // Will also work for relative and absolute hrefs
       

        $('ul.menu a').each(function () {
        if (this.href == window.location.href) {
            $(this).parents('li').find('> a').addClass("active");
        }
    });

});


/* ====================================================
   ISI
   ==================================================== */ 
// var isiWaypoint = $('#isi-waypoint');

var isiwaypoint = new Waypoint({
  element: document.getElementById('isi-waypoint'),
  handler: function(direction) {
       if(direction == 'down') {
           $('#section-isi').hide();
       }else if(direction == 'up'){
           $('#section-isi').show();
           
       }
    //console.log('Direction: ' + direction);
  },
  offset: '75%'
    
})



$(window).on("load",function(){

  setTimeout(function () {
    $(window).scrollTop(0); //scroll to top on refresh
  }); 
    
   $('.backtotop').on('click', function (e) {
       $(window).scrollTop(0);
   });   
    
 
});

//----- mobile ISI -----------------------

$(window).on("resize",function(){
    
  if($(this).width()>1023){
       $('#section-isi').removeClass('mob_isi');
       $(".s_header").unbind();

  }

  else if($(this).width() <= 1023) {  // add mobile style and button in ISI and IND
      $('#section-isi').addClass('mob_isi');
         $('.s_header').on('click', function (e) {
                  //$('.mob_isi').toggleClass('open');
                  $(this).toggleClass('isiOn');
             
          if($(this).hasClass('ind') ){ // =========f mobile IND clicked 

                 //--if ind already open close it
                  if($('.sIND').hasClass('On')){ 
                      $('.mob_isi').removeClass('open');
                      $('.sIND').removeClass('On');
                  }else{
                      $('.mob_isi').addClass('open');
                      $('.sIND').addClass('On');
                      }
                
                  //---if ISI already on - close it 
                  if($('.sISI').hasClass('On')){ 
                      $('.sISI').removeClass('On');
                      $('.s_header.isi').removeClass('isiOn');
                      
                      
                  }else{ // ---if ISI not open keep it in place
                   
                  }
                    
              
            }else{ //============================= if mobile ISI clicked  
                
                //--if isi already open close it
                if($('.sISI').hasClass('On')){ 
                      $('.mob_isi').removeClass('open');
                      $('.sISI').removeClass('On');
                      $('.s_header.ind').removeClass('isiOn');
                  }else{
                      $('.mob_isi').addClass('open');
                      $('.sISI').addClass('On');
                     
                      }
                
                //---if IND already on close it 
                if($('.sIND').hasClass('On')){
                    $('.sIND').removeClass('On');
                      $('.s_header.ind').removeClass('isiOn');
                  }else{
                  
                  }
            }
        });
                                       
      console.log("small " + $(this).width());
  }

}).trigger("resize");


/* ====================================================
    Dropdown menu on click close
   ==================================================== */
$(function(){ 
     var navMain = $("#mobile-menu");

     navMain.on("click", "a.isi", null, function () {
         navMain.css('display','none');
     });
 });


/* ====================================================
    External Link Modal Pop Ups
   ==================================================== */

/* ===========================
    Third Party Links
   =========================== */
$('a.extlink').on("mousedown touchstart", function(e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURLThirdparty = $(this).attr("href");
    warnBeforeRedirectThirdparty(linkURLThirdparty);
});

window.warnBeforeRedirectThirdparty = function warnBeforeRedirectThirdparty(linkURLThirdparty) {
    swal({
        title: "You are leaving Remicade.com",
        html: "<p>Thank you for visiting www.remicade.com.</p><p>By clicking “continue” below, you will be taken to a Web site that may contain links or references to other Web sites to which our Privacy Policy may not apply. We encourage you to read the Privacy Policy of every Web site you visit. </p><p>You are solely responsible for your interactions with such Web sites.</p>",
        cancelButtonText: 'Cancel >',
        confirmButtonText: 'Continue >', 
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: true,
        useRejections: true,
        animation: false

    }).then(okay => {
      // Redirect the user
      if (okay) {
        window.open(linkURLThirdparty);
      }
    });
};



/* ===========================
    HCP
   =========================== */
$('a.extHCP').on("mousedown touchstart", function(e) {
    e.preventDefault(); // Prevent the href from redirecting directly
    var linkURLHCP = $(this).attr("href");
    warnBeforeRedirectHCP(linkURLHCP);
});

window.warnBeforeRedirectHCP = function warnBeforeRedirectHCP(linkURLHCP) {
    swal({
        title: false,
        html: "<p>The information contained in this section of the site is intended for US healthcare professionals only. Please click the appropriate link below.</p>",
        cancelButtonText: 'Cancel >',
        customClass: 'HCP_popup',
        confirmButtonText: 'I am a US healthcare professional >', 
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: true,
        useRejections: true,
        animation: false

    }).then(okay => {
      // Redirect the user
      if (okay) {
        window.open(linkURLHCP);
      }
    });
};


/* ====================================================
    Header Navigation bar and logo change on scroll
   ==================================================== */
   var num = 1;
    $('img.nav-logo').css("display","inline-block");
    $('img.nav-logo-blue').css("display","none");

    $(window).scroll(function () { 
      num = $(window).scrollTop() / 1;
      
     if( parseInt(num) === num){
      
          $('img.nav-logo').css("display","none");
          $('img.nav-logo-blue').css("display","inline-block");
          $('.nav-bar .graybar').css("background-color","white");
          $('.menu-icon').addClass("blue-icon");
          
        }
      else{

      }
      
      if (num<1){
        $('img.nav-logo').css("display","inline-block");
        $('img.nav-logo-blue').css("display","none");
        $('.nav-bar .graybar').css("background-color","rgba(0, 36, 58, 0.5)");
        $('.menu-icon').removeClass("blue-icon");
      }
        if (num>30){
        $('img.nav-logo').css("display","none");
        $('img.nav-logo-blue').css("display","inline-block");
        $('.nav-bar .graybar').css("background-color","white");
        $('.menu-icon').addClass("blue-icon");
      }


    });


/* ====================================================
    Close Mobile Menu when clicked outside box
   ==================================================== */
$(document).ready(function(){

    $('.hamburger').on('click', function (e) {
        $(this).toggleClass('on');
        $('body').toggleClass('mobile-scroll-off'); 

    });

    $("#section-isi, #home, #learn_about, #margin-x-fix, .special_p").click(function(){
        $("#mobile-menu").css('display', 'none');
        $('body').removeClass('mobile-scroll-off'); 
    });

});




/* ====================================================
    Link Anchor
   ==================================================== */

   // for href link to #tag in different page
     setTimeout(function () {
           $('html,body').animate({
             scrollTop: $(window.location.hash).offset().top-50
           });
     },300);




